import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { useAuthStore } from './stores/authStore';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

const authStore = useAuthStore();
authStore.handleRedirect().then(() => {
  app.mount('#app');
}).catch((error) => {
  console.error('Erreur lors de la redirection:', error);
  app.mount('#app');
});


if (process.env.NODE_ENV === 'production') {
  const secretKey = 'Solaire2024SolFTO**';
  const isDebugMode = localStorage.getItem('debugKey') === secretKey;

  // Redéfinir console.log pour n'afficher que si isDebugMode est activé
  if (!isDebugMode) {
    console.log = () => {};  // Redirige les logs vers une fonction vide
  }
}
